import React, { useState } from "react";
import classes from "../css/TopNavbar.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarIcon from "@mui/icons-material/Star";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RocketIcon from "@mui/icons-material/Rocket";
import ExtensionIcon from "@mui/icons-material/Extension";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import { useNavigate } from "react-router-dom";

const TopNavbar = ({ title, active = 0 }) => {
  const [open, setOpen] = useState(false);
  const [viewCategories, setViewCategories] = useState(true);
  const [showAbout, setShowAbout] = useState(false); // State to toggle About section
  const navigate = useNavigate();

  const navigateHandler = (path) => {
    setOpen(false);
    navigate(path);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} className={classes.SideBar} role="presentation">
      <div className={classes.logo_side}>
        <img
          src="/assets/images/name_dark.png"
          alt=""
          style={{ height: "10rem", width: "12rem" }}
        />
      </div>
      <div className={classes.games}>
        <p>GAMES</p>
        <ExpandLessIcon />
      </div>
      <p
        className={classes.allgames}
        style={{ cursor: "pointer" }}
        onClick={() => navigateHandler("/allgames")}
      >
        All Games
      </p>

      <div className={classes.category_data}>
        <div className={classes.categories}>
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Categories</p>
          {viewCategories ? (
            <ExpandLessIcon onClick={() => setViewCategories(!viewCategories)} />
          ) : (
            <ExpandMoreIcon onClick={() => setViewCategories(!viewCategories)} />
          )}
        </div>
        <div className={classes.cats}>
          {viewCategories && (
            <>
              <div
                className={`${classes.category} ${
                  active === 1 && classes.active
                }`}
              >
                <p
                  onClick={() =>
                    navigateHandler("/game/13faf451-da07-432b-b7da-73bdbbe03a8b")
                  }
                >
                  <RocketIcon className={`${active === 1 && classes.icon}`} />{" "}
                  Adventure
                </p>
              </div>
              <div
                className={`${classes.category} ${
                  active === 2 && classes.active
                }`}
              >
                <p
                  onClick={() =>
                    navigateHandler("/game/e6e804fa-d074-4418-9b1c-a8db99364e3a")
                  }
                >
                  <ExtensionIcon className={`${active === 2 && classes.icon}`} />{" "}
                  Arcade
                </p>
              </div>
              <div
                className={`${classes.category} ${
                  active === 5 && classes.active
                }`}
              >
                <p
                  onClick={() =>
                    navigateHandler("/game/1f9c4256-9f5c-4463-b072-c4d2f266719e")
                  }
                >
                  <SportsVolleyballIcon
                    className={`${active === 5 && classes.icon}`}
                  />{" "}
                  Sports
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={classes.item}>FAQ's</div>

      <Divider />
      <div className={classes.favorite}>
        <StarIcon style={{ color: "red" }} /> <strong>Favourites</strong>
      </div>
      <br />
      <div className={classes.info_tag}>
        How it Works <ChevronRightIcon />
      </div>
      <hr />
      <div className={classes.info_tag}>
        Terms & Conditions <ChevronRightIcon />
      </div>
      <hr />
      <div
        className={classes.info_tag}
        onClick={() => setShowAbout(!showAbout)} // Toggle About section
        style={{ cursor: "pointer" }}
      >
        About {showAbout ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      {showAbout && ( // Conditionally render the About section
        <div className={classes.about_content}>
          {/* <p>
          YoNumbers is a subscription service which gives subscribed users access to games and/or video content while rewarding their loyalty to the service with daily airtime and cash prizes made available through daily draws.
          </p> */}
        </div>
      )}
      <hr />
      <div className={classes.side_footer}>
        <p> Copyright © 2024 YoNumbers</p>
      </div>
    </Box>
  );

  return (
    <div className={classes.main}>
      <div className={classes.top_logo_container}>
        <div className={classes.logo}>
          <img
            src="/assets/images/name_dark.png"
            alt="name"
            className={classes.top_logo_image}
          />
          <p>{title}</p>
        </div>

        <div className={classes.menu_btn} onClick={toggleDrawer(true)}>
          <MenuIcon className={classes.menu_icon} fontSize="large" />
          <p>Menu</p>
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default TopNavbar;
