import axios from "axios";

// const BASE_URL = `http://5.189.169.12:9374`;
// const BASE_URL = `http://142.132.149.12:9374`;
 //const BASE_URL = `http://localhost:7870`;
// const BASE_URL=`/`;
 const BASE_URL = `https://backend.yonumbers.com`;
// const BASE_URL = `http://192.168.1.32:7870`;
//const BASE_URL = `http://5.189.169.12:7870`;
// const PRICE_URL = `http://192.168.1.32:7875`;

export async function loginApi(data) {
  // const response = await axios.post(`${BASE_URL}/authenticate`, data);
  const response = await axios.post(`${BASE_URL}/api/v1/login`, data);
  return response;
}

export async function subscribeApi(data) {
  const response = await axios.post(`${BASE_URL}/api/v1/subscribe`, data);
  return response;
}

export async function priceByCurrencyApi(currency) {
  const response = await axios.get(
    `${BASE_URL}/api/v1/price?currency=${currency}`
  );
  return response;
}

export async function matchOtpApi(data) {
  const response = await axios.post(`${BASE_URL}/api/v1/MatchOtpRequest`, data);
  return response;
}

export async function priceApi() {
  const response = await axios.get(`${BASE_URL}/price`);
  return response;
}

// pending..
export async function getGamesApi() {
  // const response = await axios.get(`${BASE_URL}/getGames`);
  const response = await axios.get(`${BASE_URL}/api/v2/getGames`);
  return response;
}

export async function hitGameApi(gameid) {
  const response = await axios.get(`${BASE_URL}/play/${gameid}`);
  return response;
}

export async function unsubApi(number, token) {
  // const response = await axios.get(`${BASE_URL}/unsubscription/${number}`);
  const response = await axios.get(`${BASE_URL}/api/v1/auth/unsub/${number}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

// export async function getDetails(number) {
//   const response = await axios.get(`${BASE_URL}/getDetails/${number}`);
//   return response;
// }

export async function packInfoApi(ani, token) {
  const response = await axios.get(`${BASE_URL}/api/v1/auth/pack?ani=${ani}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function changeCurrencyApi(data) {
  let sendResponse;
  await axios.post(`${BASE_URL}/api/v1/changeCurrency`, data).then(
    (response) => {
      //    console.log("response||| ",response);
      sendResponse = response;
    },
    (error) => {
      // console.log(error);
      sendResponse = error.response;
    }
  );
  return sendResponse;
}

export async function getCurrencyPackApi(currencyValue) {
  const response = await axios.get(
    `${BASE_URL}/api/v1/price?currency=${currencyValue}`
  );
  return response;
}
