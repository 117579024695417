import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import classes from "../css/SubscriptionPage.module.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import TopLines from "../components/TopLines";
import SubLayout from "../components/SubLayout";
import { toast } from "react-toastify";
import {
  changeCurrencyApi,
  getCurrencyPackApi,
  loginApi,
  priceApi,
  subscribeApi,
} from "../http/http";
import ModalInfo from "../components/ModalInfo";
import Loader from "../components/Loader";

const SubscriptionPage = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [msisdn, setMsisdn] = useState("");
  const [options, setOptions] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const currencyOptions = [
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "ZWG", label: "Zimbabwe Gold (ZWG)" },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedPack, setSelectedPack] = useState(null);
  const [packOptions, setPackOptions] = useState([
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
  ]);

  const [message, setMessage] = useState({
    state: false,
    switchTo: "",
    stayWith: "",
    msisdn: "",
  });

  const [currencyPackLoading, setCurrencyPackLoading] = useState(false);

  const pressHandler = () => {
    setModal(false);
    setModalInfo("");
  };

  // useEffect(() => {
  //   getPrice();
  // }, []);

  const getPrice = async () => {
    try {
      setLoading(true);
      const response = await priceApi();
      // console.log(response.data)
      setLoading(false);
      let optArr = [];
      response?.data.map((element) => {
        optArr.push({
          value: element[0],
          label: element[0] + " " + element[1],
        });
      });
      setOptions(optArr);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.messgae ||
          error?.message ||
          error
      );
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (msisdn.trim().length === 0 || !selectedCurrency || !selectedPack) {
      // toast.error("Input Fields cannot be empty!");
      setModal(true);
      setModalInfo("Input Fields cannot be empty!");
    } else {
      try {
        setSubscribeLoading(true);
        const response = await subscribeApi({
          ani: msisdn,
          pack: selectedPack?.value,
          currency: selectedCurrency?.value,
        });
        console.log(response);

        navigate("/otp", {
          state: {
            msisdn: msisdn,
            pack: selectedOption,
            currency: selectedCurrency?.value,
            otpType:"sub",
          },
        });

        // if (response?.data?.code == 1) {
        //   setSubscribeLoading(false);

        //   setTimeout(() => {
        //     navigate("/otp", {
        //       state: { msisdn: msisdn, pack: selectedOption },
        //     });
        //   }, 1000);
        // } else if (response?.data?.code == 2) {
        //   setSubscribeLoading(false);

        //   setModal(true);
        //   setModalInfo("Billing Pending!");
        // } else if (response?.data?.code == 3) {
        //   setSubscribeLoading(false);

        //   setModal(true);
        //   setModalInfo("Subscription Expired!");
        // } else {
        //   setSubscribeLoading(false);

        //   setModal(true);
        //   setModalInfo("Something Went Wrong!");
        // }
        setSubscribeLoading(false);
      } catch (error) {
        setSubscribeLoading(false);
        console.log(error);
        if (error?.response.status == 403) {
          // toast.error("Billing Pending");
          setModal(true);
          setModalInfo("Oops, you have insufficient balance !!");
          return;
        } else if (error?.response.status === 409) {
          let optionUserIsSubscribed = currencyOptions.filter(
            (data) => data?.value != selectedCurrency?.value
          );
          setMessage({
            state: true,
            switchTo: selectedCurrency?.value,
            stayWith:
              optionUserIsSubscribed.length > 0 &&
              optionUserIsSubscribed[0]?.value,
            msisdn: msisdn,
          });
          return;
        } else {
          setModal(true);
          setModalInfo("Oops, you have insufficient balance !!");
          // toast.error("Billing Pending");
          return;
        }
      }
    }
  };

  const handleInputChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const navigate = useNavigate();
  const navigateHandler = (path) => {
    navigate(path);
  };

  const inputRef = useRef(null);

  const handleCurrencyChange = (selectedOption) => {
    if (msisdn.trim().length === 0) {
      inputRef.current.focus();
      toast.info("Please enter the number first!");
      return;
    }
    setSelectedPack(null);
    setSelectedCurrency(selectedOption);
    fetchCurrenyPacks(selectedOption?.value);
  };

  const fetchCurrenyPacks = async (currencyValue) => {
    try {
      setCurrencyPackLoading(true);
      const response = await getCurrencyPackApi(currencyValue);
      setPackOptions(
        response?.data?.map((dataItem) => {
          return {
            value: dataItem?.service,
            label:
              currencyValue === "USD"
                ? dataItem?.service + " " + "$" + dataItem?.price
                : dataItem?.service + " " + dataItem?.price,
          };
        })
      );
      setCurrencyPackLoading(false);
    } catch (error) {
      setCurrencyPackLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          error?.data ||
          error?.message ||
          error
      );
    }
  };

  const handleReset = () => {
    setMessage({
      state: false,
      switchTo: "",
      stayWith: "",
      msisdn: "",
    });
  };

  const loginApiHandler = async (currencyFromParam) => {
    try {
      setSubscribeLoading(true);
      const response = await loginApi({
        ani: msisdn,
        currency: currencyFromParam,
      });
      console.log(response);

      navigate("/otp", {
        state: {
          msisdn: msisdn,
          pack: selectedOption,
          currency: selectedCurrency?.value,
        },
      });

      // if (response?.data?.code == 1) {
      //   setSubscribeLoading(false);

      //   setTimeout(() => {
      //     navigate("/otp", {
      //       state: { msisdn: msisdn, pack: selectedOption },
      //     });
      //   }, 1000);
      // } else if (response?.data?.code == 2) {
      //   setSubscribeLoading(false);

      //   setModal(true);
      //   setModalInfo("Billing Pending!");
      // } else if (response?.data?.code == 3) {
      //   setSubscribeLoading(false);

      //   setModal(true);
      //   setModalInfo("Subscription Expired!");
      // } else {
      //   setSubscribeLoading(false);

      //   setModal(true);
      //   setModalInfo("Something Went Wrong!");
      // }
      setSubscribeLoading(false);
    } catch (error) {
      setSubscribeLoading(false);
      console.log(error);
      if (error?.response?.status === 403) {
        setModal(true);
        setModalInfo("You are not subscribed!");
      } else if (error?.response?.status === 404) {
        setModal(true);
        setModalInfo("Subscription not found. Please subscribe the services.");
      } else if (error?.response?.status === 402) {
        setModal(true);
        setModalInfo("Oops, you have insufficient balance!");
      } else if (error?.response?.status === 409) {
        console.log("enter here 2");
        let optionUserIsSubscribed = currencyOptions.filter(
          (data) => data?.value != selectedCurrency?.value
        );
        setMessage({
          state: true,
          switchTo: selectedCurrency?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: msisdn,
        });
      } else {
        setModal(true);
        setModalInfo(
          error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred."
        );
      }
    }
  };

  const handlingResponseforChange = (response) => {
    console.log("response: ", response);
    const { status, data } = response; // Destructure response status and data

    console.log("response..", status);
    console.log("data..", data);

    switch (status) {
      case 200:
      case 202: // OK
        toast.success("Currency Changed successfully");
        setMessage({
          state: false,
        });
        loginApiHandler(message.switchTo);
        break;
      case 500: // Internal Server Error (or other server errors)
      case "Network Error": // Network Error case
        setLoading(false);
        navigate("/error");
        break;
      case 409: // Network Error case
        setLoading(false);
        let optionUserIsSubscribed = currencyOptions.filter(
          (data) => data?.value != selectedCurrency?.value
        );
        setMessage({
          state: true,
          switchTo: selectedCurrency?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: msisdn,
        });
        // toast.error(data);
        // setOpenModal(true);
        break;

      case 404: // Not Found
        setLoading(false);
        setModal(true);
        setModalInfo("An unexpected error occurred.");
        break;

      case 402: // Charging Failed
        console.log("402----", status);
        setLoading(false);
        toast.error(data);
        setModal(true);
        setModalInfo("Oops! Charging Failed!");
        // setModalInfo(data);
        // Redirect to login after 2 seconds
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        break;

      default:
        setLoading(false);

        break;
    }
  };

  const handleChangeCurrency = async () => {
    console.log(message.switchTo);
    let request = { ani: message.msisdn, currency: message.switchTo };
    const response = await changeCurrencyApi(request);
    handlingResponseforChange(response);
  };

  const handlewithsame = async (currencyFromParam) => {
    toast.error(`You are already subscribed with ${message.stayWith}`);
    loginApiHandler(currencyFromParam);
  };

  return (
    <Layout>
      {/* <TopLines /> */}
      <SubLayout>
        {/* <div className={classes.main}> */}
        <div className={classes.top}></div>
        <div className={classes.title_container}>
          <img
            src="/assets/images/name_dark.png"
            alt="name"
            className={classes.name_image}
          />
        </div>

        <div className={classes.tabs_container}>
          <div className={classes.tabs_sub_container}>
            <div
              className={classes.tab_1}
              onClick={() => navigateHandler("/login")}
            >
              <p className={classes.tab}>Login</p>
            </div>
            <div
              className={classes.tab_2}
              onClick={() => navigateHandler("/subscribe")}
            >
              <p className={classes.tab}>Subscribe</p>
            </div>
          </div>
        </div>

        {message?.state == true ? (
          <div className={classes.message_box}>
            <div className={classes.back_btn} onClick={handleReset}>
              &#8617;
            </div>
            <div className={classes.wrapper_2}>
              <p className={classes.message_primary}>
                YOU ARE ALREADY SUBSCRIBED WITH {message.stayWith}
                <p className={classes.message_secondary}>DO YOU WISH TO:</p>
              </p>
              <button
                type="button"
                className={classes.switch_btn}
                disabled={loading}
                onClick={() => handleChangeCurrency()}
              >
                {`SWITCH TO ${message.switchTo}`}
              </button>
              <button
                type="button"
                className={classes.stay_with_btn}
                disabled={loading}
                onClick={() => handlewithsame(message.stayWith)}
              >
                {`STAY WITH ${message.stayWith}`}
              </button>
            </div>
          </div>
        ) : selectedCurrency != null ? (
          <div className={classes.message_box}>
            <div
              className={classes.back_btn}
              onClick={() => setSelectedCurrency(null)}
            >
              &#8617;
            </div>

            {currencyPackLoading ? (
              <Loader />
            ) : (
              <>
                <div className={classes.dropdown_selector}>
                  <div className={classes.selector}>
                    <p className={classes.selected_value}>
                      {selectedPack != null
                        ? selectedPack?.label
                        : "Select a pack"}
                    </p>
                    <div className={classes.wrapper}>
                      <div className={classes.line_breaker}></div>
                      <div className={classes.chevron_down}></div>
                    </div>
                  </div>

                  <div className={classes.selector_options}>
                    {packOptions.map((optionValue, idx) => {
                      return (
                        <div
                          className={`${classes.option} ${
                            selectedPack?.value == optionValue?.value &&
                            classes.selected
                          }`}
                          key={idx}
                          onClick={() => setSelectedPack(optionValue)}
                        >
                          <p className={classes.option_text}>
                            {optionValue?.label}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <button
                  type="button"
                  onClick={submitHandler}
                  className={classes.subscribe_btn}
                >
                  Subscribe
                </button>
              </>
            )}
          </div>
        ) : (
          <div className={classes.form_container}>
            <form className={classes.form} onSubmit={submitHandler}>
              <div className={classes.input_group}>
                <span className={classes.country_code}>+263</span>
                <input
                  className={classes.input}
                  type="number"
                  placeholder="ENTER YOUR PHONE NUMBER"
                  value={msisdn}
                  onChange={(e) => setMsisdn(e.target.value)}
                ></input>
              </div>

              {loading ? (
                <Loader />
              ) : (
                <div className={classes.dropdown_selector}>
                  <div className={classes.selector}>
                    <p className={classes.selected_value}>
                      {selectedCurrency != null
                        ? selectedCurrency?.label
                        : "Select a currency"}
                    </p>
                    <div className={classes.wrapper}>
                      <div className={classes.line_breaker}></div>
                      <div className={classes.chevron_down}></div>
                    </div>
                  </div>

                  <div className={classes.selector_options}>
                    {currencyOptions.map((optionValue, idx) => {
                      return (
                        <div
                          className={`${classes.option} ${
                            selectedCurrency?.value == optionValue?.value &&
                            classes.selected
                          }`}
                          key={idx}
                          onClick={() => handleCurrencyChange(optionValue)}
                        >
                          <p className={classes.option_text}>
                            {optionValue?.label}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <button
                type="submit"
                className={classes.subscribe_btn}
                disabled={subscribeLoading}
                style={{
                  opacity: subscribeLoading ? "0.3" : "1",
                }}
              >
                Subscribe
              </button>
            </form>
          </div>
        )}

        {subscribeLoading && <Loader />}

        <div className={classes.footer_container}>
          <div className={classes.footer_sub_container}>
            <img
              src="/assets/images/econet_logo.png"
              alt="econet"
              className={classes.footer}
            />
            <p className={classes.footer_text}>
              By clicking subscribe, you have read, understood and agree to be
              bound by the YoNumbers service’s Terms & Conditions and FAQ’s
            </p>
          </div>
        </div>
        {/* </div> */}
      </SubLayout>
      {modal && <ModalInfo text={modalInfo} pressHandler={pressHandler} />}
    </Layout>
  );
};

export default SubscriptionPage;
