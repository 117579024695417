import React, { useEffect, useState } from "react";
import classes from "../css/ProfilePage.module.css";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import SubscriptionInfo from "../components/SubscriptionInfo";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { packInfoApi, unsubApi } from "../http/http";
import { getCookie, removeCookie } from "../utils/helper";
import Spinner from "../components/Spinner";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [pack, setPack] = useState("");

  const handleUnsubClick = async () => {
    try {
      setLoading(true);
      const msisdn = getCookie("msisdn");
      const token = getCookie("token");
      const response = await unsubApi(msisdn, token);
      console.log(response, "response....");
      toast.success("UnSubscribed Successfully!");
      removeCookie();
      navigate("/subscribe");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 403) {
        toast.error("Token Expired, Login Again!");
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else {
        // removeCookie();
        // navigate("/login");
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    }
  };

  useEffect(() => {
    getPackDetails();
  }, []);

  const getPackDetails = async () => {
    try {
      const token = getCookie("token");
      const response = await packInfoApi(getCookie("msisdn"), token);
      setPack(response?.data);
      // setPack(
      //   response?.data?.pack
      //     ?.replace("LGAMING_M", "Monthly")
      //     ?.replace("LGAMING_D", "Daily")
      //     ?.replace("LGAMING_W", "Weekly")
      // );
      console.log(response, "response...");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };
  return (
    <Layout>
      <SubLayout>
        <TopNavbar />
        <div className={classes.container}>
          <SubscriptionInfo pack={pack} />
          <div className={classes.line}></div>
          <div className={classes.btn_container}>
            <button onClick={() => navigate("/home")} className={classes.btn}>
              Continue Playing
            </button>
          </div>
          <div className={classes.padding}>
            <div className={classes.unsubscribe_container}>
              <div className={classes.image_text_container}>
                <img
                  src="/assets/images/cat.png"
                  alt="cat"
                  className={classes.cat}
                />
                <div className={classes.text_container}>
                  <h3 className={classes.text}>Unsubscribe</h3>
                  <p className={classes.p}>
                    It’s free and fun to play on YoNumbers!
                  </p>
                </div>
              </div>
              <button
                className={`${classes.btn} ${classes.btn2} ${
                  loading && classes.disabled
                }`}
                onClick={handleUnsubClick}
                disabled={loading}
              >
                UNSUBSCRIBE
                {loading && <Spinner />}
              </button>
            </div>
          </div>
        </div>

        <Footer active={3} />
      </SubLayout>
    </Layout>
  );
};

export default ProfilePage;
