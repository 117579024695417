import Cookies from "js-cookie";

export function setCookie(msisdn) {
  Cookies.set("msisdn", msisdn, {
    expires: 1,
  });
}

export function removeCookie() {
  Cookies.remove("msisdn");
  Cookies.remove("token");
}

export function getCookie(name) {
  const value = Cookies.get(name.toString());
  return value;
}

export function setCookieToken(token) {
  Cookies.set("token", token, {
    expires: 1,
  });
}
