import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import Layout from "../components/Layout";
import TopLines from "../components/TopLines";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import classes from "../css/Home.module.css";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../utils/helper";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Carousel from "../components/CarouselComponent";
import { toast } from "react-toastify";
import { getGamesApi, hitGameApi } from "../http/http";
import Loader from "../components/Loader";
import classes2 from "../css/Games.module.css";
import { Link } from "react-router-dom";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [winners, setWinners] = useState(null);
  const [games, setGames] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const navigateHandler = (age) => {
    const msisdn = getCookie("msisdn");
    if (!msisdn) {
      navigate("/login");
    } else {
      navigate(`/LilOnes?msisdn=${msisdn}&age=${age}`);
    }
  };

  const navigateGameHandler = async (itemData) => {
    try {
      setLoader(true);
      // const response = await hitGameApi(itemData?.id);
      // const url = response?.data?.gameurl?.data?.url;
      // console.log(url);
      window.location.href = itemData?.game_url;
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const transformGamesToArray = (games) => {
    if (!games || typeof games !== "object") return [];

    return Object.entries(games).map(([category, items]) => ({
      category,
      items,
    }));
  };

  const fetchGames = async () => {
    try {
      setLoader(true);
      const response = await getGamesApi();
      setWinners(response?.data?.winners);
      setGames(transformGamesToArray(response?.data?.games));
      setLoader(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchGames();
  }, []);
  console.log(games, "games...");
  return (
    <>
      {loading ? (
        <Loading />
      ) : loader ? (
        <Layout>
          <Loader />
        </Layout>
      ) : (
        <Layout>
          {/* <TopLines /> */}

          <SubLayout>
            <TopNavbar title="Home" />

            <div className={classes.main_container}>
              <div className={classes.title_container}>
                <h4 className={classes.title}>{winners?.winning_date}</h4>
              </div>
              <div className={classes.number}>{winners?.totalwinners}</div>
              <div className={classes.text}>
                <p> Subscribers won yesterday and the winning number was: </p>
              </div>
              <div className={classes.code}>
                <div className={classes.digit}>{winners?.winner_number[0]}</div>
                <div className={classes.digit}>{winners?.winner_number[1]}</div>
                <div className={classes.digit}>{winners?.winner_number[2]}</div>
                <div className={classes.digit}>{winners?.winner_number[3]}</div>
              </div>
              <div className={classes.card}>
                <p>
                  Dial <strong>*647#</strong> and stand a chance to Win Airtime
                  & More!
                </p>
                <button onClick={() => navigate("/information")}>
                  How it Works
                </button>
              </div>

              {/* <div className={classes.carousel}>
                <Carousel games={games} />
              </div> */}
            </div>

            <div className={classes2.container}>
              {games.length > 0 &&
                games.map((item, index) => (
                  <React.Fragment key={index} >
                    <div className={classes2.top}>
                      <div className={classes2.cat}>{item?.category}</div>

                      <div className={classes2.seeAll}>
                        <Link to="/allgames">
                          <p
                            style={{
                              cursor: "pointer",
                              color: "#E92230",
                              fontSize: "1.5rem",
                            }}
                          >
                            See All
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className={classes2.games}>
                      {item?.items?.map((itemData, index2) => {
                        return (
                          <div
                            className={classes2.card}
                            key={index2}
                            // onClick={() => navigate(`/game/${item?.id}`)}
                            onClick={() => navigateGameHandler(itemData)}
                          >
                            <img
                              src={itemData?.game_image}
                              alt={itemData?.game_name}
                            />
                            <p>{itemData?.game_name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                ))}
            </div>

            <Footer active={0} />
          </SubLayout>
        </Layout>
      )}
    </>
  );
};

export default Home;
