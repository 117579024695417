import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import TopLines from "../components/TopLines";
import SubLayout from "../components/SubLayout";
import classes from "../css/OtpPage.module.css";
import OTPInput from "react-otp-input";
import TopLogo from "../components/TopNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setCookie, setCookieToken } from "../utils/helper";
import { loginApi, matchOtpApi } from "../http/http";
import ModalInfo from "../components/ModalInfo";
import Loader from "../components/Loader";

const OtpPage = () => {
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  const [msisdn, setMsisdn] = useState("");
  const [pack, setPack] = useState("");
  const [currency, setCurrency] = useState("");
  const [resend, setResend] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState("");
  const [loading, setLoading] = useState(false);


  const [otpType, setOtpType] = useState("");

  const pressHandler = () => {
    setModal(false);
    setModalInfo("");
  };

  const location = useLocation();

  useEffect(() => {
    const packSelected = location.state?.pack;
    const number = location.state?.msisdn;
    const currencySelected = location.state?.currency;
    const otpType = location.state?.otpType;
    if (!number) {
      navigate("/login");
      return;
    }
    setMsisdn(number);
    setPack(packSelected);
    setCurrency(currencySelected);
    setOtpType(otpType);
    
  }, [location.state]);

  useEffect(() => {
    if (msisdn) {
      if (timer <= 0 || !msisdn) {
        navigate("/login");
      }
    }
  }, [msisdn, timer]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  const otpHandler = async () => {
    try {
      if (otp.length !== 4) {
        setModal(true);
        setModalInfo("Enter the valid otp number of 4 digits!");
        return;
      } else {
        setLoading(true);
        const response = await matchOtpApi({
          ani: msisdn,
          otp: otp,
          currency: currency,
          otpType : otpType,
        });
        setLoading(false);
        console.log(response, "response");

        if (response.status === 200) {
          // OTP matched
          setLoading(false);
          setCookie(msisdn);
          setCookieToken(response?.data?.token);
          // setCookieUserId(response?.data?.userId);
          // setCookieUserId(response?.data?.subscriptionId);
          setTimeout(() => {
            setOTP("");
            navigate("/home");
          }, 3000);
        } else {
          // Unauthorized response (OTP wrong)
          setLoading(false);
          setModal(true);
          setModalInfo("Wrong OTP");
          // Optionally, handle specific non-200 status codes if needed
        }

        // if (
        //   response?.data?.code == 3 &&
        //   response?.data?.message == "Invalid Otp"
        // ) {
        //   setLoading(false);
        //   setModal(true);
        //   setModalInfo("Invalid Otp");
        //   return;
        // } else if (response?.data?.code == 1) {
        //   setLoading(false);
        //   setTimeout(() => {
        //     setOTP("");
        //     setCookie(msisdn);
        //     // navigate("/auth");
        //     navigate("/home");
        //   }, 3000);
        // } else if (
        //   response?.data?.code == 3 &&
        //   response?.data?.code == "Expire Otp"
        // ) {
        //   setLoading(false);
        //   setModal(true);
        //   setModalInfo("OTP Expired");
        //   setTimeout(() => {
        //     setOTP("");
        //     navigate("/login");
        //   }, 3000);
        //   return;
        // } else {
        //   setLoading(false);
        //   setModal(true);
        //   setModalInfo("OTP Expired Or Invalid Otp , Retry Again!");
        // }
      }
    } catch (error) {
      setLoading(false);
      setModal(true);
      setModalInfo("Wrong OTP");
    }
  };

  const resendHandler = async () => {
    try {
      const response = await loginApi({ ani: msisdn, currency: currency });
      if (response?.data === 0) {
        toast.error("You are not subscribed!");
        navigate("/login");
      } else if (response?.data === 2) {
        toast.error("Billing Pending");
        navigate("/login");
      } else if (response?.data === 1) {
        setResend(true);
        // navigate("/otp", { state: { msisdn: msisdn, pack: null } });
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <Layout>
      {/* <TopLines /> */}
      <SubLayout>
        {/* <TopLogo /> */}
        <div className={classes.main}>
          <div className={classes.otp_description}>
            <h2 className={classes.title}>OTP Verification</h2>
            <p className={classes.text}>
              We have sent the send {resend && "another"} one time pin to
              <span className={classes.bold}> +263{msisdn} </span>
              via SMS.
            </p>
          </div>

          <div className={classes.otp_input_container}>
            <OTPInput
              value={otp}
              shouldAutoFocus
              onChange={setOTP}
              numInputs={4}
              renderSeparator={<span className={classes.gap}></span>}
              renderInput={(props) => (
                <input {...props} type="number" className={classes.input_box} />
              )}
            />
          </div>

          <div className={classes.time_container}>
            <p className={classes.time_text}>Time remaining: {timer} seconds</p>
          </div>

          <div className={classes.btn_container}>
            <button
              type="button"
              className={classes.confirm_btn}
              onClick={otpHandler}
              disabled={loading}
              style={{ opacity: loading ? "0.3" : "1" }}
            >
              Confirm
            </button>
            {loading && <Loader />}
            {/* <button type="button" className={classes.resend_btn}>
              LOST PIN?
            </button>
            <button type="button" className={classes.resend_btn}>
              HAVE'NT RECEIVED PIN YET?
            </button>
            <button type="button" className={classes.resend_btn}>
              PIN EXPIRED?
            </button> */}
            <button
              type="button"
              className={classes.resend_btn}
              onClick={() => resendHandler()}
            >
              RESEND PIN?
            </button>
          </div>

          <div className={classes.nameImage_container}>
            <img
              src="/assets/images/name_dark.png"
              alt="name"
              className={classes.name_image}
            />
          </div>

          <div className={classes.footer_container}>
            <div className={classes.footer_sub_container}>
              <img
                src="/assets/images/econet_logo.png"
                alt="econet"
                className={classes.footer}
              />
              <p className={classes.footer_text}>
                By clicking subscribe, you have read, understood and agree to be
                bound by the MyKidzHub service’s Terms & Conditions and FAQ’s
              </p>
            </div>
          </div>
        </div>
      </SubLayout>
      {modal && <ModalInfo text={modalInfo} pressHandler={pressHandler} />}
    </Layout>
  );
};

export default OtpPage;
