import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";
import classes from "../css/Games.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getGamesApi, hitGameApi } from "../http/http";
import Loader from "../components/Loader";

const Games = () => {
  const [loader, setLoader] = useState(true);
  const [games, setGames] = useState([]);
  const navigate = useNavigate();

  const transformGamesToArray = (games) => {
    if (!games || typeof games !== "object") return [];

    return Object.entries(games).map(([category, items]) => ({
      category,
      items,
    }));
  };

  const fetchGames = async () => {
    try {
      setLoader(true);
      const response = await getGamesApi();
      console.log(response, "response");
      setGames(transformGamesToArray(response?.data?.games));
      setLoader(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const navigateHandler = async (itemData) => {
    try {
      setLoader(true);
      // const response = await hitGameApi(itemData?.id);
      // const url = response?.data?.gameurl?.data?.url;
      // console.log(url);
      setLoader(false);
      window.location.href = itemData?.game_url;
    } catch (error) {
      setLoader(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <Layout>
      {loader ? (
        <Loader />
      ) : (
        <SubLayout>
          <TopNavbar title="Games" />
          <div className={classes.container}>
            {games.length > 0 &&
              games?.map((item, index) => (
                <React.Fragment key={index}>
                  <div className={classes.top}>
                    <div className={classes.cat}>{item?.category}</div>

                    <div className={classes.seeAll}>
                      {/* <Link to={`/game/${item?.categoryid}`}> */}
                      <Link to="/allgames">
                        <p
                          style={{
                            cursor: "pointer",
                            color: "#E92230",
                            fontSize: "1.5rem",
                          }}
                        >
                          See All
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className={classes.games}>
                    {item?.items?.map((itemData, index2) => {
                      return (
                        <div
                          className={classes.card}
                          key={index2}
                          onClick={() => navigateHandler(itemData)}
                        >
                          <img
                            src={itemData?.game_image}
                            alt={itemData?.game_name}
                          />
                          <p>{itemData?.game_name}</p>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              ))}
          </div>
        </SubLayout>
      )}
      <Footer active={1} />
    </Layout>
  );
};

export default Games;
