import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import classes from "../css/AllGames.module.css";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import GradeIcon from "@mui/icons-material/Grade";
import Footer from "../components/Footer";
import { getGamesApi, hitGameApi } from "../http/http";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const AllGames = () => {
  const [loader, setLoader] = useState(true);
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = games.filter((game) => {
      return game?.items?.some((itemData) => {
        return itemData?.game_name.toLowerCase().includes(searchQuery);
      });
    });

    setFilteredGames(filtered);
  };

  console.log(filteredGames, "fg");

  useEffect(() => {
    if (searchQuery.trim().length == 0) {
      setFilteredGames(games);
    }
  }, [searchQuery]);

  const transformGamesToArray = (games) => {
    if (!games || typeof games !== "object") return [];

    return Object.entries(games).map(([category, items]) => ({
      category,
      items,
    }));
  };

  const fetchGames = async () => {
    try {
      setLoader(true);
      const response = await getGamesApi();
      setGames(transformGamesToArray(response?.data?.games));
      setFilteredGames(transformGamesToArray(response?.data?.games));
      console.log(response, "response");
      setLoader(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const navigateHandler = async (itemData) => {
    try {
      setLoader(true);
      // const response = await hitGameApi(itemData?.id);
      // const url = response?.data?.gameurl?.data?.url;
      // console.log(url);
      setLoader(false);
      window.location.href = itemData?.game_url;
    } catch (error) {
      setLoader(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const arr = [
    {
      src: "/assets/images/badland.png",
      name: "BadLand",
    },
    {
      src: "/assets/images/1941Frozen.png",
      name: "BadLand",
    },
    {
      src: "/assets/images/AngryGran.png",
      name: "BadLand",
    },
    {
      src: "/assets/images/AlphaGuns.png",
      name: "BadLand",
    },
    {
      src: "/assets/images/ArcheryHero.png",
      name: "BadLand",
    },
    {
      src: "/assets/images/BabyAva.png",
      name: "BadLand",
    },
  ];

  return (
    <Layout>
      {loader ? (
        <Loader />
      ) : (
        <>
          <SubLayout>
            <TopNavbar title="All Games" />
            <div className={classes.main_container}>
              <div className={classes.header}>
                <h2 className={classes.title}>All Games</h2>
                <div className={classes.search}>
                  <SearchIcon className={classes.icon} />
                  <input
                    type="text"
                    placeholder="search"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <ArrowForwardIcon className={classes.icon} />
                </div>
              </div>
              <div className={classes.games}>
                {filteredGames?.map((item, idx) => {
                  return item?.items?.map((itemData, index) => {
                    return (
                      <div
                        className={classes.item}
                        key={itemData?.id}
                        onClick={() => navigateHandler(itemData)}
                      >
                        <div className={classes.star_icon}>
                          <GradeIcon fontSize="large" style={{}} />
                        </div>
                        <div className={classes.image}>
                          <img
                            src={itemData.game_image}
                            alt={itemData?.game_name}
                            className={classes.img}
                          />
                        </div>
                        <div className={classes.text}>
                          <p>{itemData?.game_name}</p>
                        </div>
                        <div className={classes.button}>
                          <button>Play Now</button>
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </SubLayout>
        </>
      )}
      <Footer active={1} />
    </Layout>
  );
};

export default AllGames;
